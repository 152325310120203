




























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import store from "@/store";
import Credits from "@/components/Credits.vue";
import { easync } from "@/utils/http";
import { showErrorToast } from "@/utils/Toast";
import { authStore } from "@/store/typed";

@Component({ components: { Credits } })
export default class Home extends Vue {
  private user = {
    email: "",
    password: ""
  };

  async submit() {
    const [res, errors] = await easync(
      // store.dispatch("login", this.user)
      authStore.login(this.user)
    );

    if (errors) {
      showErrorToast();
      return;
    }

    if (res) {
      window.location.reload();
    }
//     store.dispatch("login", this.user).then(
//       user => {
//         window.location.reload();
//       },
//      ( err: any) => {
//   console.log(err)
// }
//     );
  }
}
